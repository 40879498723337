<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4 mt-5">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="d-flex justify-content-start align-items-center">
                <img id="artwork-image" class="rounded-circle mr-4 border"
                  style="object-fit: cover; width: 80px; height: 80px" :src="user.avatar && user?.avatar[0]?.url
                    ? user?.avatar[0]?.url
                    : 'img/user-defualt.svg'
                    " alt="" loading="lazy" />
                <div class="d-flex flex-column">
                  <span class="header-1-p">
                    {{ user.username }} <i v-if="user.type_id == 1" class="fas fa-certificate"></i>
                  </span>
                  <span class="text-2-s">
                    {{ user.name_en }}
                  </span>
                  <span class="text-4-g">
                    Join since {{ daysAgo(user.created_at) }}
                  </span>
                </div>
              </div>
              <div class="mt-3 w-100">
                <p class="text-3-g">
                  {{ user.about_en }}
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-flex justify-content-start justify-content-md-end align-items-center mt-4 mt-lg-0">
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <hr class="m-0" />
        </div>

        <div class="col-12 col-lg-12 mt-5 mb-5">
          <div id="profile" class="tab-content">
            <div class="row">
              <div class="col-md-12">
                <div v-if="isLoading">
                  <ArtistSkeleton />
                </div>
                <div v-else>


                  <div v-if="user?.artworks && user?.artworks?.data"
                    class="row mb-5 mx-2 mx-md-0 justify-content-start">
                    <div v-for="artwork in user?.artworks?.data" :key="artwork.index"
                      class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 p-0 mb-3">
                      <div>
                        <div class="card card-custom bg-dark text-white border-0 m-0 ml-0 mr-0 mb-3 mx-auto mx-md-0">
                          <img class="card-img" style="object-fit: cover"
                            :style="`${Object.keys(artwork.initial).length > 0 && artwork.initial[Object.keys(artwork.initial)[0]]?.color ? 'background-color: ' + artwork.initial[Object.keys(artwork.initial)[0]]?.color : 'background-color: #fff;'}`"
                            :src="artwork?.cover_image && artwork?.cover_image[0]?.original_url
                              ? artwork?.cover_image[0]?.original_url
                              : artwork?.image[0]?.original_url
                                ? artwork?.image[0]?.original_url
                                : '/img/placeholders/block.png'
                              " alt="Card image" @click.prevent="
                                router.push({
                                  name: 'Artwork',
                                  params: { id: artwork?.slug },
                                })
                                " />
                          <div class="btn-fav">
                            <img @click.prevent="addToWishlist(artwork?.id, isLoggedIn)" :src="wishlistIDs.includes(artwork?.id)
                              ? 'img/favorite.svg'
                              : 'img/non-favorite.svg'
                              " />
                          </div>
                          <div class="img-footer">
                            <span v-if="
                              formatDateYYYYMMDD(artwork?.created_at) ==
                              formatDateYYYYMMDD(Date.now())
                            " class="badge badge-secondary p-2">
                              New
                            </span>
                          </div>
                        </div>
                        <div @click.prevent="
                          router.push({
                            name: 'Artwork',
                            params: { id: artwork?.slug },
                          })
                          " class="d-flex align-items-start flex-column m-pointer mx-auto mx-md-0 card-custom-info">
                          <span class="header-3-s one-line-text">
                            {{ artwork?.name_en }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="artwork-pages">
                  <div v-if="user?.artworks" class="
                                                    w-100
                                                    d-flex
                                                    justify-content-between
                                                    align-items-center
                                                    mt-3
                                                  ">
                    <div>
                      <p class="text-muted text-sm mb-0">
                        Showing {{ user?.artworks?.from }} to
                        {{ user?.artworks?.to }} of
                        {{ user?.artworks?.total }}
                      </p>
                    </div>


                    <!-- <pagination :data="users?.artworks" :limit="1" @pagination-change-page="(page) => { }" /> -->

                    <div>
                      <LaravelVuePagination :data="user?.artworks" :limit="1" @pagination-change-page="(page) => {
                        filters.page = page
                        getArtistProfile(route.params.slug, filters)
                      }
                        " />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import useUsers from "@/composables/Users";
import { useShopping } from "@/composables/Shopping";

import LaravelVuePagination from "@/components/Pagination/LaravelVuePagination.vue";
import ArtistSkeleton from "../../components/ArtistSkeleton.vue";

const Pagination = LaravelVuePagination;

const router = useRouter();
const route = useRoute();

const page = ref(1);
const per_page = ref(12);
const per_page_options = ref([
  { text: "12", value: 12 },
  { text: "24", value: 24 },
  { text: "50", value: 50 },
  { text: "100", value: 100 }
]);
const orderColumnOptions = ref([
  { text: "Date", value: "created_at" },
  { text: "Name", value: "name_en" },
]);
const orderDirectionOptions = ref([
  { text: "ASC", value: "asc" },
  { text: "DESC", value: "desc" },
]);

const {
  getArtistProfile,
  user,
} = useUsers();

const filters = ref({
  page: 1,
  per_page: 12,
  order_column: 'created_at',
  order_direction: 'desc',
  search: {
    name_en: '',
    search_global: '',
  }
});

const isLoading = ref(true);

const {
  addToWishlist,
  wishlistIDs,
} = useShopping();

onMounted(async () => {
  const pageNumber = route.query.page && !isNaN(route.query.page) ? Number(route.query.page) : 1;
  page.value = pageNumber;
  await getArtistProfile(route.params.slug, filters.value)

  setTimeout(() => {
    isLoading.value = false
  }, 1500);
});

// watch(search_global, () => {
//   getUser(
//     logedinUser.id,
//     1,
//     search_global.value,
//     orderColumn.value,
//     orderDirection.value,
//     per_page.value
//   );
// });
watch(() => route.query.page, (current) => {
  const pageNumber = current ? parseInt(current, 10) : 1;
  page.value = pageNumber;
});

watch(() => page.value, (current) => {
  filters.value.page = current;
  getArtistProfile(route.params.slug, filters.value);
})
const formatDateYYYYMMDD = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const daysAgo = (date) => {
  const date1 = new Date(date);
  const date2 = new Date();
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
  const diffh = Math.round(diffTime / (1000 * 60 * 60));
  const diffm = Math.round(diffTime / (1000 * 60));
  const diffs = Math.round(diffTime / 1000);
  if (diffs < 60) return diffs + "s ago";
  if (diffm < 60) return diffm + "m ago";
  if (diffh < 24) return diffh + "h ago";
  if (diffDays < 1) return diffDays + "day ago";
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(date1).toLocaleDateString("en-us", options);
};

</script>
<style scoped>
.card.card-custom:hover {
  cursor: pointer;
}

.card-custom {
  width: 180px;
  height: 180px;
  margin-bottom: 0.5rem;
}

.card-custom .card-img {
  height: 100%;
}

.pad-0 {
  padding-right: 0 !important;
  padding-bottom: 1rem !important;
}

.color {
  height: 15px;
  width: 15px;
}

.side-menu {
  display: flex;
  flex-direction: column;
}

.side-menu .menu-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.side-menu .menu-list .list-item {
  cursor: pointer;
  margin-top: 1rem;
  padding-left: calc(0.7rem + 4px);
  color: var(--gray-pxl);
}

.side-menu .menu-list .list-item.active {
  padding-left: 0.7rem;
  border-left: 4px solid var(--primary-pxl);
  color: var(--secondary-pxl);
  animation-name: fadeInBorder;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

.tab-content.active {
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}
</style>

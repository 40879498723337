<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-12 mb-4">
          <h3 class="header-1-p">Coupon Code</h3>
          <div class="d-flex justify-content-between align-items-center">
            <InputText placeholder="Enter a coupon code here" v-model="discountCode"
              :disabled="Object.keys(coupon).length > 0" class="w-75" />
            <Button v-if="(!couponProcessing && Object.keys(coupon).length == 0)" class="w-25"
              style="text-align: center;" @click.prevent="applyCoupon()">
              <span class="w-100 text-bold-3-s w-100 text-white text-center">Apply</span>
            </Button>
            <Button v-else-if="(!couponProcessing && Object.keys(coupon).length > 0)" class="w-25"
              style="text-align: center;" @click.prevent="removeCoupon()">
              <span class="w-100 text-bold-3-s w-100 text-white text-center">Remove</span>
            </Button>
            <Button v-else icon="pi pi-spin pi-spinner" class="w-25" />
          </div>
          <div v-if="couponValidation" class="d-flex flex-column justify-content-start">
            <span class="text-danger">
              {{ couponValidation }}
            </span>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-12">
              <h3 class="header-1-p">Your Order</h3>
            </div>
            <div class="col-md-12">
              <div class="table-responsive artwork-list">
                <table class="table">
                  <tbody>
                    <tr v-for="(item, index) in cartItems" :key="item.index">
                      <td colspan="3" class="col-3 p-0">
                        <img class="" style="object-fit: cover; width: 90px;"
                          :style="`${Object.keys(item.artwork.initial).length > 0 && item.artwork.initial[Object.keys(item.artwork.initial)[0]]?.color ? 'background-color: ' + item.artwork.initial[Object.keys(item.artwork.initial)[0]]?.color : 'background-color: #fff;'}`"
                          :src="item.artwork &&
                            item?.artwork?.cover_image[0]?.webp
                            ? item?.artwork?.cover_image[0]?.webp
                            : item?.artwork?.image[0]?.webp
                              ? item?.artwork?.image[0]?.webp
                              : '/img/placeholders/block.png'
                            " alt="" loading="lazy" />
                      </td>
                      <td colspan="5" class="col-5 align-middle">
                        <div class="header-2-s">
                          {{ item?.artwork?.name_en }}
                        </div>
                        <div v-if="item.options" class="art-details d-flex justify-content-start">
                          <div v-for="(option, index) in item.options" :key="option.index" class="
                                                      pr-2
                                                      text-gray text-capitalize
                                                      d-flex
                                                      align-items-center
                                                    ">
                            <span>{{ index }}: &nbsp;</span>
                            <span v-if="index == 'color'" class="dot" :style="[{ 'background-color': option }]">
                            </span>
                            <span v-else class="text-light">
                              {{ option }}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td colspan="2" class="col-2 align-middle">
                        <div class="art-price">
                          <span class="header-3-s" :class="{ 'text-c': item?.product?.discount > 0 }">
                            {{ currency?.code }} {{ Number(item?.product?.price * currency?.exchange_rate).toFixed(3) }}
                          </span>
                          <span v-if="item?.product?.discount > 0" class="header-3-p pl-2">
                            {{ currency?.code }} {{ Number((item?.product?.price - item?.product?.discount) *
                              currency?.exchange_rate).toFixed(3) }}
                          </span>
                        </div>
                      </td>
                      <td class="col-2 align-middle">
                        <div class="
                                                    col-md-12
                                                    d-flex
                                                    justify-content-left
                                                    align-items-center
                                                    btn-quantity
                                                  ">
                          <span class="btn-q" @click.prevent="
                            updateCartItemQuantity(
                              isLoggedIn,
                              logedinUser.id,
                              item,
                              -1
                            )
                            ">
                            <svg id="menu-add-plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24">
                              <g id="Group_478" data-name="Group 478" transform="translate(-2 -2)">
                                <line id="Line_21" data-name="Line 21" x2="9" transform="translate(10 14.5)" fill="none"
                                  stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                              </g>
                              <path id="Path_289" data-name="Path 289" d="M0,0H24V24H0Z" fill="none" />
                            </svg>
                          </span>
                          <span class="header-3-s">{{ item?.quantity }}</span>
                          <span class="btn-q" @click.prevent="
                            updateCartItemQuantity(
                              isLoggedIn,
                              logedinUser.id,
                              item,
                              1
                            )
                            ">
                            <svg id="menu-add-plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24">
                              <g id="Group_478" data-name="Group 478" transform="translate(-2 -2)">
                                <line id="Line_20" data-name="Line 20" y2="9" transform="translate(14.5 10)" fill="none"
                                  stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <line id="Line_21" data-name="Line 21" x2="9" transform="translate(10 14.5)" fill="none"
                                  stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                              </g>
                              <path id="Path_289" data-name="Path 289" d="M0,0H24V24H0Z" fill="none" />
                            </svg>
                          </span>
                        </div>
                      </td>
                      <!-- <td colspan="1" class="col-1 pr-0 align-middle">
                        <button class="btn-custom-light" @click.prevent="
                          deleteCartItem(
                            item.id,
                            isLoggedIn,
                            logedinUser.id,
                            index
                          )
                        ">
                          <svg id="trash-delete-bin" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24">
                            <path id="Path_315" data-name="Path 315"
                              d="M6,9V19H6a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V9m-4,1v7m-4-7v7M4.5,6h15M8,6l.544-1.632h0A2,2,0,0,1,10.442,3h3.116a2,2,0,0,1,1.9,1.368L16,6"
                              fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2" />
                            <rect id="Rectangle_96" data-name="Rectangle 96" width="24" height="24" fill="none" />
                          </svg>
                        </button>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <h3 class="header-1-p">Order Notes</h3>
            </div>
            <div class="col-md-12">
              <textarea v-model="orderData.order_notes" class="form-control" name="order_notes" id="order_notes"
                cols="30" rows="5"></textarea>
            </div>
            <div class="col-md-12 mt-3">
              <h3 class="header-1-p">Summary</h3>
            </div>
            <div v-if="cartAmount && cartAmount > 0" class="col-md-12 d-flex justify-content-between">
              <span class="header-2-s">Amount</span>
              <span class="text-2-g">
                {{ currency?.code }} {{ Number(cartAmount * currency?.exchange_rate).toFixed(3) }}
              </span>
            </div>
            <div v-if="cartDiscount && cartDiscount > 0" class="col-md-12 d-flex justify-content-between">
              <span class="header-2-s">Discount</span>
              <span class="text-2-g">
                - {{ currency?.code }} {{ Number(cartDiscount * currency?.exchange_rate).toFixed(3) }}
              </span>
            </div>
            <div v-if="orderData?.coupon_discount && orderData.coupon_discount > 0"
              class="col-md-12 d-flex justify-content-between">
              <span class="header-2-s">Coupon</span>
              <span class="text-2-g">
                - {{ currency?.code }} {{ Number(orderData.coupon_discount * currency?.exchange_rate).toFixed(3) }}
              </span>
            </div>
            <div v-if="orderData && orderData.shipping" class="col-md-12 d-flex justify-content-between">
              <span class="header-2-s">Shipping</span>
              <span class="text-2-g">
                {{ currency?.code }} {{ Number(orderData.shipping * currency?.exchange_rate).toFixed(3) }}
              </span>
            </div>
            <div class="col-md-12 d-flex justify-content-between">
              <span class="header-2-s">Total</span>
              <span class="text-2-g">
                {{ currency?.code }} {{ Number(orderData.total * currency?.exchange_rate).toFixed(3) }}
              </span>
            </div>
            <div
              v-if="checkoutMeta.payment_methods && ((Number(orderData.total * currency?.exchange_rate).toFixed(3)) != 0)"
              class="col-md-12 mt-3">
              <div v-for="methods in checkoutMeta.payment_methods.filter(
                (methods) => methods.is_active == 1
              )" :key="methods.index" class="form-check">
                <div
                  v-if="methods.name_en === 'Cash' && orderData.billing_address.country !== 'Bahrain' || methods.name_en === 'Debit Card' && orderData.billing_address.country !== 'Bahrain'">
                </div>
                <div v-else>
                  <input v-model="orderData.payment_method" class="form-check-input" type="radio"
                    :name="methods.name_en" :id="methods.name_en" :value="methods.name_en" />
                  <label class="form-check-label header-3-s" :for="methods.name_en">
                    {{ methods.name_en }}
                  </label>
                </div>
              </div>
              <div class="row m-0 w-100" v-if="checkoutErrors?.payment_method">
                <span class="text-danger">
                  {{ checkoutErrors?.payment_method }}
                </span>
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <Button :disabled="isLoading == true" class="w-100"
                @click.prevent="placeOrder(orderData, isLoggedIn, logedinUser.id)">
                <p class="text-bold-3-s w-100 text-white text-center">
                  <span v-if="isLoading == false">Place Order</span>
                  <ProgressSpinner v-else-if="isLoading == true" style="width: 20px; height: 20px;" strokeWidth="5" />
                </p>
              </Button>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12">
              <h3 class="header-1-p">Delivery Address</h3>
            </div>
            <div v-if="isLoggedIn == true" class="col-md-12">
              <div class="w-100">
                <Dropdown class="w-100" v-if="logedinUser && logedinUser?.data?.address" v-model="delivery_address"
                  :options="logedinUser?.data?.address" placeholder="Select a address">
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">
                      <div>
                        Country: {{ slotProps?.value?.country?.name_en }}, City: {{ slotProps?.value?.city?.name_en }},
                        Villa: {{ slotProps?.value?.villa }}, Flat:{{ slotProps?.value?.flat }} , Road: {{
                          slotProps?.value?.road }}, Block: {{
                          slotProps?.value?.block }}
                      </div>
                    </div>
                    <span v-else>
                      {{ slotProps?.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <div>
                        Country: {{ slotProps?.option?.country?.name_en }}, City: {{ slotProps?.option?.city?.name_en
                        }},
                        Villa: {{ slotProps?.option?.villa }},Flat:{{ slotProps?.value?.flat }}, Road: {{
                          slotProps?.option?.road }}, Block: {{
                          slotProps?.option?.block }}
                      </div>
                    </div>
                  </template>
                </Dropdown>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="name_en" class="form-control-label header-3-s">Name
                  <md />
                </label>
                <input v-model="orderData.delivery_address.name_en" class="form-control" id="name_en" type="text"
                  name="name_en" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.delivery_address?.name_en">
                  <span class="text-danger">
                    {{ checkoutErrors?.delivery_address?.name_en }}
                  </span>
                </div>
              </div>
              <div class="form-group d-flex flex-column justify-content-start">
                <label for="country" class="form-control-label header-3-s">Country
                  <md />
                </label>
                <Dropdown v-model="orderData.delivery_address.country" :filter="true" :options="checkoutMeta?.country"
                  optionLabel="name_en" optionValue="name_en" placeholder="Select a country" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.delivery_address?.country">
                  <span class="text-danger">
                    {{ checkoutErrors?.delivery_address?.country }}
                  </span>
                </div>
              </div>
              <div v-if="checkoutMeta?.city" class="form-group d-flex flex-column justify-content-start">
                <label for="city" class="form-control-label header-3-s">City
                  <md />
                </label>
                <Dropdown class="w-100" v-model="orderData.delivery_address.city" :filter="true" :options="checkoutMeta?.city.filter(
                  (city) =>
                    city?.country?.name_en ==
                    orderData?.delivery_address?.country
                )" optionLabel="name_en" optionValue="name_en" placeholder="Select a city" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.delivery_address?.city">
                  <span class="text-danger">
                    {{ checkoutErrors?.delivery_address?.city }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="villa" class="form-control-label header-3-s">Villa
                  <md />
                </label>
                <input v-model="orderData.delivery_address.villa" class="form-control" id="villa" type="text"
                  name="villa" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.delivery_address?.villa">
                  <span class="text-danger">
                    {{ checkoutErrors?.delivery_address?.villa }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="flat" class="form-control-label header-3-s">Flat
                  <!-- <md /> -->
                </label>
                <input v-model="orderData.delivery_address.flat" class="form-control" id="flat" type="text"
                  name="flat" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.delivery_address?.flat">
                  <span class="text-danger">
                    {{ checkoutErrors?.delivery_address?.flat }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="road" class="form-control-label header-3-s">Road
                  <md />
                </label>
                <input v-model="orderData.delivery_address.road" class="form-control" id="road" type="text"
                  name="road" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.delivery_address?.road">
                  <span class="text-danger">
                    {{ checkoutErrors?.delivery_address?.road }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="block" class="form-control-label header-3-s">Block
                  <md />
                </label>
                <input v-model="orderData.delivery_address.block" class="form-control" id="block" type="text"
                  name="block" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.delivery_address?.block">
                  <span class="text-danger">
                    {{ checkoutErrors?.delivery_address?.block }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="post_code" class="form-control-label header-3-s">Post code
                </label>
                <input v-model="orderData.delivery_address.post_code" class="form-control" id="post_code" type="text"
                  name="post_code" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.delivery_address?.post_code">
                  <span class="text-danger">
                    {{ checkoutErrors?.delivery_address?.post_code }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="mobile" class="form-control-label header-3-s">Mobile
                  <md />
                </label>
                <div class="mobile-input-container">
                  <Dropdown class="w-100" v-model="orderData.billing_address.mobilecode"
                    :options="checkoutMeta?.country" optionLabel="mobilecode" optionValue="mobilecode"
                    placeholder="Code" :filter="true" style="height: 2.85rem; width: 8rem !important;" />

                  <div v-if="!orderData.billing_address.mobilecode">
                    <div class="row m-0 w-100" v-if="checkoutErrors?.delivery_address?.mobilecode">
                      <span class="text-danger">
                        {{ checkoutErrors?.delivery_address?.mobilecode }}
                      </span>
                    </div>
                  </div>
                  <input v-model="orderData.delivery_address.mobile" class="form-control" id="mobile" type="text"
                    name="mobile" />
                </div>

                <div class="row m-0 w-100" v-if="checkoutErrors?.delivery_address?.mobile">
                  <span class="text-danger">
                    {{ checkoutErrors?.delivery_address?.mobile }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="email" class="form-control-label header-3-s">Email
                  <md />
                </label>
                <input v-model="orderData.delivery_address.email" class="form-control" id="email" type="email"
                  name="email" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.delivery_address?.email">
                  <span class="text-danger">
                    {{ checkoutErrors?.delivery_address?.email }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-12 align-middle">
              <label for="different_address" class="header-1-p">Bill to a different Address
              </label>
              &nbsp;
              <input v-model="orderData.different_address" id="different_address" type="checkbox"
                name="different_address" />
            </div>
            <div v-if="isLoggedIn == true && orderData.different_address == true" class="col-md-12">
              <div class="w-100">
                <Dropdown class="w-100" v-if="logedinUser && logedinUser?.data?.address" v-model="billing_address"
                  :options="logedinUser?.data?.address" optionLabel="id" optionValue="id"
                  placeholder="Select a address" />
              </div>
            </div>
            <div v-if="orderData.different_address == true" class="col-md-12">
              <div class="form-group">
                <label for="name_en" class="form-control-label header-3-s">Name
                  <md />
                </label>
                <input v-model="orderData.billing_address.name_en" class="form-control" id="name_en" type="text"
                  name="name_en" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.billing_address?.name_en">
                  <span class="text-danger">
                    {{ checkoutErrors?.billing_address?.name_en }}
                  </span>
                </div>
              </div>
              <div class="form-group d-flex flex-column justify-content-start">
                <label for="country" class="form-control-label header-3-s">Country
                  <md />
                </label>
                <Dropdown class="w-100" v-model="orderData.billing_address.country" :options="checkoutMeta?.country"
                  optionLabel="name_en" optionValue="name_en" placeholder="Select a country" :filter="true" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.billing_address?.country">
                  <span class="text-danger">
                    {{ checkoutErrors?.billing_address?.country }}
                  </span>
                </div>
              </div>
              <div class="form-group d-flex flex-column justify-content-start">
                <label for="city" class="form-control-label header-3-s">City
                  <md />
                </label>
                <Dropdown class="w-100" v-model="orderData.billing_address.city" :filter="true" :options="checkoutMeta?.city.filter(
                  (city) =>
                    city?.country?.name_en ==
                    orderData?.delivery_address?.country
                )" optionLabel="name_en" optionValue="name_en" placeholder="Select a City" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.billing_address?.city">
                  <span class="text-danger">
                    {{ checkoutErrors?.billing_address?.city }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="villa" class="form-control-label header-3-s">Villa
                  <md />
                </label>
                <input v-model="orderData.billing_address.villa" class="form-control" id="villa" type="text"
                  name="villa" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.billing_address?.villa">
                  <span class="text-danger">
                    {{ checkoutErrors?.billing_address?.villa }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="flat" class="form-control-label header-3-s">Flat
                  <md />
                </label>
                <input v-model="orderData.billing_address.flat" class="form-control" id="flat" type="text"
                  name="flat" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.billing_address?.flat">
                  <span class="text-danger">
                    {{ checkoutErrors?.billing_address?.flat }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="road" class="form-control-label header-3-s">Road
                  <md />
                </label>
                <input v-model="orderData.billing_address.road" class="form-control" id="road" type="text"
                  name="road" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.billing_address?.road">
                  <span class="text-danger">
                    {{ checkoutErrors?.billing_address?.road }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="block" class="form-control-label header-3-s">Block
                  <md />
                </label>
                <input v-model="orderData.billing_address.block" class="form-control" id="block" type="text"
                  name="block" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.billing_address?.block">
                  <span class="text-danger">
                    {{ checkoutErrors?.billing_address?.block }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="post_code" class="form-control-label header-3-s">Post code
                  <md />
                </label>
                <input v-model="orderData.billing_address.post_code" class="form-control" id="post_code" type="text"
                  name="post_code" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.billing_address?.post_code">
                  <span class="text-danger">
                    {{ checkoutErrors?.billing_address?.post_code }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <div class="mobile-input-container">
                  <Dropdown class="w-100" v-model="orderData.billing_address.mobilecode"
                    :options="checkoutMeta?.country" optionLabel="mobilecode" optionValue="mobilecode"
                    placeholder="Code" :filter="true" style="height: 2.85rem; width: 8rem !important;" />

                  <div v-if="!orderData.billing_address.mobilecode">
                    <div class="row m-0 w-100" v-if="checkoutErrors?.delivery_address?.mobilecode">
                      <span class="text-danger">
                        {{ checkoutErrors?.delivery_address?.mobilecode }}
                      </span>
                    </div>
                  </div>
                  <input v-model="orderData.delivery_address.mobile" class="form-control" id="mobile" type="text"
                    name="mobile" />
                </div>
              </div>
              <div class="form-group">
                <label for="email" class="form-control-label header-3-s">Email
                  <md />
                </label>
                <input v-model="orderData.billing_address.email" class="form-control" id="email" type="email"
                  name="email" />
                <div class="row m-0 w-100" v-if="checkoutErrors?.billing_address?.email">
                  <span class="text-danger">
                    {{ checkoutErrors?.billing_address?.email }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { cartItems, useShopping } from "@/composables/Shopping";
import { useCoupons } from "@/composables/Coupons";
import { isLoggedIn, logedinUser } from "@/composables/auth";
import useArtworks from "@/composables/Artworks";
import { useCurrency } from "@/composables/GlobalState";



export default {
  components: {
  },
  setup() {
    const { artworks, getArtworks } = useArtworks();
    const {
      isLoading,
      getUserCartItems,
      updateCartItemQuantity,
      deleteCartItem,
      cartTotal,
      cartDiscount,
      cartAmount,
      placeOrder,
      getCheckoutMeta,
      checkoutMeta,
      checkoutErrors,
    } = useShopping();
    const { findCoupon } = useCoupons()
    const router = useRouter();
    const route = useRoute();

    const { currency } = useCurrency()

    const coupon = ref({});
    const discountCode = ref('');
    const couponsDiscount = ref(0);
    const couponValidation = ref('');
    const couponProcessing = ref(false)

    const delivery_address = ref({});
    const billing_address = ref({});

    const shipping_methods = ref([]);
    const selected_shipping = ref(null);

    const orderData = ref({
      delivery_address: {
        name_en: "",
        name_ar: "",
        country: "",
        city: "",
        villa: "",
        flat: "",
        road: "",
        block: "",
        post_code: "",
        mobile: "",
        mobilecode: "",
        email: "",
      },
      billing_address: {
        name_en: "",
        name_ar: "",
        country: "",
        city: "",
        villa: "",
        flat: "",
        road: "",
        block: "",
        post_code: "",
        mobile: "",
        mobilecode: "",
        email: "",
      },
      different_address: false,
      order_notes: "",
      payment_method: "",
      subtotal: 0,
      shipping: 0,
      discount: 0,
      coupon_discount: 0,
      coupon: '',
      total: 0,
    });

    onMounted(async () => {
      await getUserCartItems(isLoggedIn.value, logedinUser.id);
      await getArtworks(1, "", "", "", "", "", "", "", "", "id", "desc", 6, 1);
      await getCheckoutMeta();
      if (coupon.value?.type && coupon.value?.value) {
        // calculateCoupon()
      }
      orderData.value.shipping = 0;
      orderData.value.discount = cartDiscount.value ? cartDiscount.value : 0;
      orderData.value.subtotal = cartAmount.value ? cartAmount.value : 0;
      orderData.value.total = cartTotal.value ? cartTotal.value : 0;
      if (logedinUser && logedinUser.data) {

        orderData.value.delivery_address.name_en = logedinUser.data.name_en
          ? logedinUser.data.name_en
          : "";
        orderData.value.delivery_address.name_ar = logedinUser.data.name_ar
          ? logedinUser.data.name_ar
          : "";
        orderData.value.delivery_address.mobile = logedinUser.data.mobile
          ? logedinUser.data.mobile
          : "";
        orderData.value.delivery_address.mobilecode = logedinUser.data.mobilecode
          ? logedinUser.data.mobilecode
          : "";
        orderData.value.delivery_address.email = logedinUser.data.email
          ? logedinUser.data.email
          : "";
        orderData.value.billing_address = orderData.value.delivery_address;
      }
      if (logedinUser && logedinUser.data && logedinUser?.data?.address) {
        let userAddress = logedinUser?.data?.address[0];
        delivery_address.value = userAddress;
        orderData.value.delivery_address.country = userAddress.country.name_en;
        orderData.value.delivery_address.city = userAddress.city.name_en;
        orderData.value.delivery_address.block = userAddress.block;
        orderData.value.delivery_address.road = userAddress.road;
        orderData.value.delivery_address.villa = userAddress.villa;
        orderData.value.delivery_address.flat = userAddress.flat;
        orderData.value.delivery_address.post_code = userAddress.post_code;
        orderData.value.billing_address = orderData.value.delivery_address;
      }
      //check shippings
      if (checkoutMeta.value.country) {
        if (!orderData.value.delivery_address.country) {
          orderData.value.delivery_address.country =
            checkoutMeta.value.country[0].name_en;
        }
        for (const country of checkoutMeta.value.country) {
          if (country.name_en == orderData.value.delivery_address.country) {
            shipping_methods.value = country.shippings[0].shipping_methods;
            selected_shipping.value =
              country.shippings[0].shipping_methods[0].name_en;
            orderData.value.shipping = Number(
              country.shippings[0].shipping_methods[0].amount
            );
            orderData.value.total =
              cartAmount.value + orderData.value.shipping - cartDiscount.value;
            cartTotal.value =
              cartAmount.value + orderData.value.shipping - cartDiscount.value;
          }
        }
      }
    });

    const defaultPayment = ref(null);

    const calculateCoupon = async () => {
      if (coupon.value?.type == 'fixed') {
        couponsDiscount.value = coupon.value?.value
        orderData.value.coupon_discount = coupon.value?.value
      } else if (coupon.value?.type == 'percent') {
        couponsDiscount.value = (cartAmount.value - cartDiscount.value) * (coupon.value?.value / 100)
        orderData.value.coupon_discount = (cartAmount.value - cartDiscount.value) * (coupon.value?.value / 100)
      }
      orderData.value.total = cartAmount.value + orderData.value.shipping - couponsDiscount.value - cartDiscount.value;
      cartTotal.value = cartAmount.value + orderData.value.shipping - couponsDiscount.value - cartDiscount.value;
      if (cartTotal.value < 0) {
        cartTotal.value = 0
      }
      if (orderData.value.total < 0) {
        orderData.value.total = 0;
      }
    }


    const applyCoupon = async () => {
      const data = {
        coupon: discountCode.value
      }
      couponValidation.value = ''
      couponProcessing.value = true
      await findCoupon(data).then((response) => {
        if (response?.data?.data && response?.data?.data?.code) {
          coupon.value = response.data.data
          orderData.value.coupon = response?.data?.data?.code
          couponValidation.value = ''
        } else {
          couponValidation.value = 'Something want wrong!'
        }
      }).catch((error) => {
        if (error?.response?.data?.message) {
          couponValidation.value = error.response.data.message
        } else {
          couponValidation.value = 'Something want wrong!'
        }
      }).finally(() => couponProcessing.value = false)
      calculateCoupon()
    }

    const removeCoupon = async () => {
      coupon.value = {}
      discountCode.value = ''
      couponValidation.value = ''
      couponsDiscount.value = 0
      orderData.value.coupon = ''
      orderData.value.coupon_discount = 0
      orderData.value.discount = cartDiscount.value ? cartDiscount.value : 0;
      orderData.value.subtotal = cartAmount.value ? cartAmount.value : 0;
      cartTotal.value = cartAmount.value + orderData.value.shipping - couponsDiscount.value - cartDiscount.value;
      orderData.value.total = cartTotal.value ? cartTotal.value : 0;
    }

    watch(
      () => cartItems.value,
      async (current) => {
        calculateCoupon()
      },
      { deep: true }
    );

    watch(() => orderData.value.total, async (current) => {
      if (current == 0) {
        orderData.value.payment_method = "Free Coupon";
      }
    })

    // watch(
    //   () => orderData.value,
    //   async (current) => {
    //     calculateCoupon()
    //   },
    //   { deep: true }
    // );

    watch(
      () => delivery_address.value,
      async (current) => {
        let userAddress = null;
        if (logedinUser && logedinUser?.data) {
          userAddress = logedinUser?.data?.address.filter(
            (address) => address.id == current.id
          )[0];
          orderData.value.delivery_address.country =
            userAddress.country.name_en;
          orderData.value.delivery_address.city = userAddress.city.name_en;
          orderData.value.delivery_address.block = userAddress.block;
          orderData.value.delivery_address.road = userAddress.road;
          orderData.value.delivery_address.villa = userAddress.villa;
          orderData.value.delivery_address.flat = userAddress.flat;
          orderData.value.delivery_address.post_code = userAddress.post_code;
          orderData.value.billing_address = orderData.value.delivery_address;
        }
      }
    );

    watch(
      () => orderData.value.delivery_address.country,
      async (current) => {
        // console.log('orderData b4', orderData.value.payment_method);
        if (orderData.value.payment_method) {
          orderData.value.payment_method = "";
        }
        // console.log('orderData after', orderData.value.payment_method);
        if (checkoutMeta.value.country) {
          if (!orderData.value.delivery_address.country) {
            orderData.value.delivery_address.country =
              checkoutMeta.value.country[0].name_en;
          }
          for (const country of checkoutMeta.value.country) {
            if (country.name_en == orderData.value.delivery_address.country) {
              shipping_methods.value = country.shippings[0].shipping_methods;
              selected_shipping.value =
                country.shippings[0].shipping_methods[0].name_en;
              orderData.value.shipping = Number(
                country.shippings[0].shipping_methods[0].amount
              );
              orderData.value.total =
                cartAmount.value +
                orderData.value.shipping -
                cartDiscount.value;
              cartTotal.value =
                cartAmount.value +
                orderData.value.shipping -
                cartDiscount.value;
            }
          }
        }
      },
      {
        deep: true,
      }
    );

    watch(
      () => billing_address.value,
      async (current) => {
        let userAddress = null;
        if (logedinUser && logedinUser?.data) {
          userAddress = logedinUser?.data?.address.filter(
            (address) => address.id == current
          )[0];
          orderData.value.billing_address.country = userAddress.country.name_en;
          orderData.value.billing_address.city = userAddress.city.name_en;
          orderData.value.billing_address.block = userAddress.block;
          orderData.value.billing_address.road = userAddress.road;
          orderData.value.billing_address.villa = userAddress.villa;
          orderData.value.billing_address.flat = userAddress.flat;
          orderData.value.billing_address.post_code = userAddress.post_code;
        }
      }
    );

    return {
      isLoading,
      isLoggedIn,
      logedinUser,
      artworks,
      cartItems,
      cartTotal,
      cartDiscount,
      cartAmount,
      updateCartItemQuantity,
      deleteCartItem,
      placeOrder,
      router,
      route,
      orderData,
      delivery_address,
      billing_address,
      checkoutMeta,
      checkoutErrors,
      shipping_methods,
      applyCoupon,
      removeCoupon,
      coupon,
      discountCode,
      couponProcessing,
      couponValidation,
      currency,
    };
  },
};
</script>
<style scoped>
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.mobile-input-container {
  display: flex;
  align-items: center;
}

.mobile-input {
  flex-grow: 1;
  /* Allow the first input to grow and take the available space */
  margin-right: 0.5rem;
  /* Add some spacing between the inputs */
}
</style>
